import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import { BsPencilSquare } from "react-icons/bs";
import ListGroup from "react-bootstrap/ListGroup";
import { LinkContainer } from "react-router-bootstrap";
import { useAppContext } from "../../libs/contextLib";
import { onError } from "../../libs/errorLib";
import Button from "react-bootstrap/Button";
import "./Inventory.css";

export default function Inventory() {
    const [inventory, setInventory] = useState([]);
    const { isAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function onLoad() {
            if (!isAuthenticated) {
                return;
            }

            try {
                const inventory = await loadInventory();
                setInventory(inventory);
            } catch (e) {
                console.log(e);
                onError(e);
            }

            setIsLoading(false);
        }

        onLoad();
    }, [isAuthenticated]);

    function loadInventory() {
        return API.get("inventory", "/inventory");
    }

    function getObjectType(object) {
        return object.objectUuid.split(':')[0];
    }

    function renderInventoryList(inventory) {
        return (
            <>
                <LinkContainer to="/inventory/new">
                    <ListGroup.Item action className="py-3 text-nowrap text-truncate">
                        <BsPencilSquare size={17} />
                        <span className="ml-2 font-weight-bold">Create an inventory change</span>
                    </ListGroup.Item>
                </LinkContainer>

                <ListGroup>
                    {inventory.map((object) => (
                        getObjectType(object) == "product" && object.stockValue != 0 &&
                        <ListGroup.Item action>

                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">{object.productName}</h5>
                                <small>Total in stock: {object.stockValue}</small>
                            </div>
                            <ListGroup>
                                {object.locations.map((object) => (
                                    getObjectType(object) == "inventory" && object.locationType == 'STORE' &&
                                    <ListGroup.Item action>
                                        <ListGroup horizontal>
                                            <LinkContainer key={object.objectUuid} to={`/product/${object.objectUuid}`}>
                                                <ListGroup.Item>
                                                    <span className="font-weight-bold location-name">
                                                        {object.locationName}
                                                    </span>
                                                    <br/>
                                                    <span className="text-muted">
                                                    {object.stockValue} in stock
                                                </span>
                                                </ListGroup.Item>
                                            </LinkContainer>
                                            <ListGroup.Item>
                                                <LinkContainer key={object.objectUuid} to={`/inventory/${object.objectUuid}?action=add`}>
                                                    <Button variant="success" size="lg" active>Add stock</Button>
                                                </LinkContainer>
                                            </ListGroup.Item>


                                            <ListGroup.Item>
                                                <LinkContainer key={object.objectUuid} to={`/inventory/${object.objectUuid}?action=decrese`}>
                                                    <Button variant="warning" size="lg" active>Reduce stock</Button>
                                                </LinkContainer>
                                            </ListGroup.Item>

                                        </ListGroup>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </ListGroup.Item>

                    ))}

                </ListGroup>
            </>
        );
    }

    function renderLander() {
        return (
            <div className="lander">
                <h1>inven2ry{process.env.REACT_APP_STAGE == 'prod' ? ' - production' : ' - test'}</h1>
                <p className="text-muted">Your webshop warehouse partner</p>
                <div className="pt-3">
                    <Link to="/login" className="btn btn-info btn-lg mr-3">
                        Login
          </Link>
                    <Link to="/signup" className="btn btn-success btn-lg">
                        Signup
          </Link>
                </div>
            </div>
        );
    }

    function renderInventory() {
        return (
            <div className="inventory">
                <h2 className="pb-3 mt-4 mb-3 border-bottom">Your Inventory</h2>
                <ListGroup>{!isLoading && renderInventoryList(inventory)}</ListGroup>
            </div>
        );
    }

    return (
        <div className="Inventory">
            {isAuthenticated ? renderInventory() : renderLander()}
        </div>
    );
}