import React, { useRef, useState, useEffect } from "react";
import { API, Storage } from "aws-amplify";
import { Form } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import config from "../../config";
import { useParams, useHistory } from "react-router-dom";
import "./EditArticle.css";
import { onError } from "../../libs/errorLib";

export default function EditArticle() {
  const history = useHistory();
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {

    function loadProduct() {
      return API.get("inventory", `/articles/${id}`);
    }

    async function onLoad() {

      try {

        const article = await loadProduct();
        const { name } = article;
        setName(name);
        setArticle(article);

      } catch (e) {

        onError(e);

      }

    }

    onLoad();

  }, [id]);


  function validateForm() {
    return true;
  }


  function saveArticle(article) {
    return API.put("inventory", `/articles/${id}`, {
      body: article
    });
  }

  async function handleSubmit(event) {

    event.preventDefault();

    setIsLoading(true);

    try {

      await saveArticle({

        name

      });

      history.push("/articles");

    } catch (e) {

      onError(e);
      setIsLoading(false);

    }
  }

  function deleteArticle() {
    return API.del("inventory", `/articles/${id}`);
  }

  async function handleDelete(event) {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this article"
    );

    if (!confirmed) {
      return;
    }

    setIsDeleting(true);

    try {

      await deleteArticle();

      history.push("/articles");

    } catch (e) {

      onError(e);
      setIsDeleting(false);

    }
  }



  return (
    <div className="Article">
      {article && (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="name">
            Name
            <Form.Control
              as="input"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </Form.Group>
          <LoaderButton
            block
            size="lg"
            type="submit"
            isLoading={isLoading}
            disabled={!validateForm()}
          >
            Save
          </LoaderButton>
          <LoaderButton
            block
            size="lg"
            variant="danger"
            onClick={handleDelete}
            isLoading={isDeleting}
          >
            Delete
          </LoaderButton>
        </Form>
      )}
    </div>
  );

}