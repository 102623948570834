import React, { useRef, useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { API, Storage } from "aws-amplify";
import { useParams, useHistory } from "react-router-dom";
import LoaderButton from "../../components/LoaderButton";
import { onError } from "../../libs/errorLib";
import "./EditProduct.css";

export default function Product() {
  const { id } = useParams();
  const history = useHistory();
  const [product, setProduct] = useState(null);
  const [productName, setProductName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    function loadProduct() {
      return API.get("inventory", `/products/${id}`);
    }

    async function onLoad() {

      try {

        const product = await loadProduct();
        const { productName } = product;

        setProductName(productName);
        setProduct(product);

      } catch (e) {

        onError(e);

      }

    }

    onLoad();

  }, [id]);

  function validateForm() {
    return productName.length > 0;
  }


  function saveProduct(product) {
    return API.put("inventory", `/products/${id}`, {
      body: product
    });
  }

  async function handleSubmit(event) {

    event.preventDefault();

    setIsLoading(true);

    try {

      await saveProduct({

        productName

      });

      history.push("/products");

    } catch (e) {

      onError(e);
      setIsLoading(false);

    }

  }

  function deleteProduct() {
    return API.del("inventory", `/products/${id}`);
  }

  async function handleDelete(event) {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this product?"
    );

    if (!confirmed) {
      return;
    }

    setIsDeleting(true);

    try {

      await deleteProduct();

      history.push("/products");

    } catch (e) {

      onError(e);
      setIsDeleting(false);

    }

  }

  return (
    <div className="Product">
      {product && (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="productName">
            Name
            <Form.Control
              as="input"
              onChange={(e) => setProductName(e.target.value)}
              value={productName}
            />
          </Form.Group>
          <LoaderButton
            block
            size="lg"
            type="submit"
            isLoading={isLoading}
            disabled={!validateForm()}
          >
            Save
          </LoaderButton>
          <LoaderButton
            block
            size="lg"
            variant="danger"
            onClick={handleDelete}
            isLoading={isDeleting}
          >
            Delete
          </LoaderButton>
        </Form>
      )}
    </div>
  );
}
