import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import { BsPencilSquare } from "react-icons/bs";
import ListGroup from "react-bootstrap/ListGroup";
import { LinkContainer } from "react-router-bootstrap";
import { useAppContext } from "../../libs/contextLib";
import { onError } from "../../libs/errorLib";
import "./Products.css";

export default function Product() {
  const [products, setProducts] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      try {
        const products = await loadProducts();
        setProducts(products);
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  function loadProducts() {
    return API.get("inventory", "/products");
  }

  function renderProductsList(products) {
    return (
      <>
        <LinkContainer to="/products/new">
          <ListGroup.Item action className="py-3 text-nowrap text-truncate">
            <BsPencilSquare size={17} />
            <span className="ml-2 font-weight-bold">Create a new product</span>
          </ListGroup.Item>
        </LinkContainer>
        {products.map(({ objectUuid, productId, productName, created }) => (
          <LinkContainer key={objectUuid} to={`/product/${objectUuid}`}>
            <ListGroup.Item action>
              <span className="font-weight-bold">
                {productName} {productId ? ' - ' : ''} {productId ? productId : ''}
              </span>
              <br />
              <span className="text-muted">
                Created: {new Date(created).toLocaleString()}
              </span>
            </ListGroup.Item>
          </LinkContainer>
        ))}
      </>
    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>inven2ry.com</h1>
        <p className="text-muted">Your webshop warehouse partner</p>
        <div className="pt-3">
          <Link to="/login" className="btn btn-info btn-lg mr-3">
            Login
          </Link>
          <Link to="/signup" className="btn btn-success btn-lg">
            Signup
          </Link>
        </div>
      </div>
    );
  }

  function renderProducts() {
    return (
      <div className="products">
        <h2 className="pb-3 mt-4 mb-3 border-bottom">Your Products</h2>
        <ListGroup>{!isLoading && renderProductsList(products)}</ListGroup>
      </div>
    );
  }

  return (
    <div className="Product">
      {isAuthenticated ? renderProducts() : renderLander()}
    </div>
  );
}
