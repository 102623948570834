const dev = {
  s3: {
    REGION: "YOUR_S3_UPLOADS_BUCKET_REGION",
    BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME",
  },
  apiGateway: {
    REGION: "eu-north-1",
    URL: "https://umzhvt1pd1.execute-api.eu-north-1.amazonaws.com/dev",
  },
  cognito: {
    REGION: "eu-north-1",
    USER_POOL_ID: "eu-north-1_cg9zMoGZF",
    APP_CLIENT_ID: "jo7jl3iqc0f0f75sb58vvkkac",
    IDENTITY_POOL_ID: "eu-north-1:3b75370f-b36f-4990-924b-9a4a75ed589b"
  },
};

const prod = {
  s3: {
    REGION: "YOUR_S3_UPLOADS_BUCKET_REGION",
    BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME",
  },
  apiGateway: {
    REGION: "eu-north-1",
    URL: "https://k6z00wxj7b.execute-api.eu-north-1.amazonaws.com/prod",
  },
  cognito: {
    REGION: "eu-north-1",
    USER_POOL_ID: "eu-north-1_VSTN0s0lX",
    APP_CLIENT_ID: "7gqdaar37s58ivonsavp2dn0ue",
    IDENTITY_POOL_ID: "eu-north-1:c898a339-7310-4fcd-a578-f951f7c87cf3"
  },
};

const config = {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  // Default to dev if not set
  ...(process.env.REACT_APP_STAGE === "prod" ? prod : dev),
};

export default config;
