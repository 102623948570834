import React, { Component } from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import "./Settings.css";
import { API } from "aws-amplify";

export default class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      settings: [],
    };
  }

  async componentDidMount() {

    if (!this.props.isAuthenticated) {
      return;
    }

    try {

      const settings = await this.settings();
      this.setState({ settings });

    } catch (e) {

      alert(e);

    }

    this.setState({ isLoading: false });

  }

  settings() {

    return API.get("inventory", `/settings`);

  }

  handleNoteClick = event => {
    event.preventDefault();
    this.props.history.push(event.currentTarget.getAttribute("href"));
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>inven2ry.com</h1>
        <p>Your webshop warehouse partner</p>
      </div>
    );
  }

  renderSettings() {
    return (
      <div className="settings">
        <h2 className="pb-3 mt-4 mb-3 border-bottom">Inven2ry Settings</h2>
        <ListGroup>

        </ListGroup>
      </div>
    );
  }

  render() {

    return (
      <div className="Settings">
        {this.props.isAuthenticated ? this.renderSettings() : this.renderLander()}
      </div>
    );

  }

}