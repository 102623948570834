import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import { BsPencilSquare } from "react-icons/bs";
import ListGroup from "react-bootstrap/ListGroup";
import { LinkContainer } from "react-router-bootstrap";
import { useAppContext } from "../../libs/contextLib";
import { onError } from "../../libs/errorLib";
import "./Locations.css";

export default function Location() {
  const [locations, setLocations] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      try {
        const locations = await loadLocations();
        setLocations(locations);
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  function loadLocations() {
    return API.get("inventory", "/locations");
  }

  function renderLocationsList(locations) {
    return (
      <>
        <LinkContainer to="/locations/new">
          <ListGroup.Item action className="py-3 text-nowrap text-truncate">
            <BsPencilSquare size={17} />
            <span className="ml-2 font-weight-bold">Create a new location</span>
          </ListGroup.Item>
        </LinkContainer>
        {locations.map(({ objectUuid, locationId, locationName, created }) => (
          <LinkContainer key={objectUuid} to={`/location/${objectUuid}`}>
            <ListGroup.Item action>
              <span className="font-weight-bold">
                {locationName} {locationId ? ' - ' : ''} {locationId ? locationId : ''}
              </span>
              <br />
              <span className="text-muted">
                Created: {new Date(created).toLocaleString()}
              </span>
            </ListGroup.Item>
          </LinkContainer>
        ))}
      </>
    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>inven2ry.com</h1>
        <p className="text-muted">Your webshop warehouse partner</p>
        <div className="pt-3">
          <Link to="/login" className="btn btn-info btn-lg mr-3">
            Login
          </Link>
          <Link to="/signup" className="btn btn-success btn-lg">
            Signup
          </Link>
        </div>
      </div>
    );
  }

  function renderLocations() {
    return (
      <div className="locations">
        <h2 className="pb-3 mt-4 mb-3 border-bottom">Your Locations</h2>
        <ListGroup>{!isLoading && renderLocationsList(locations)}</ListGroup>
      </div>
    );
  }

  return (
    <div className="Location">
      {isAuthenticated ? renderLocations() : renderLander()}
    </div>
  );
}
