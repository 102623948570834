import React, { useRef, useState, useEffect } from "react";
import { API } from "aws-amplify";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import LoaderButton from "../../components/LoaderButton";
import { onError } from "../../libs/errorLib";
import "./NewInventory.css";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useAppContext } from "../../libs/contextLib";



export default function NewInventory() {
  const history = useHistory();
  const [products, setProducts] = useState([]);
  const [locations, setLocations] = useState([]);
  const [productUuid, setProductUuid] = useState("");
  const [fromLocationUuid, setFromLocationUuid] = useState("");
  const [toLocationUuid, setToLocationUuid] = useState("");
  const [changeValue, setChangeValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { isAuthenticated } = useAppContext();

  function validateForm() {
    return changeValue.length > 0 && !isNaN(changeValue) && productUuid.length >0 &&fromLocationUuid.length >0 && toLocationUuid.length >0  ;
  }

  useEffect(() => {
    async function onLoad() {

      if (!isAuthenticated) {
        return;
      }

      try {
        const products = await loadProducts();
        setProducts(products);

        const locations = await loadLocations();
        setLocations(locations);

      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await createInventory({ productUuid, fromLocationUuid, toLocationUuid, changeValue });
      history.push("/inventory");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function createInventory(product) {
    return API.put("inventory", "/inventory", {
      body: product
    });
  }

  function loadProducts() {
    return API.get("inventory", "/products");
  }

  function loadLocations() {
    return API.get("inventory", "/locations");
  }

  function handleProductChange(selected) {
    if (selected[0] != undefined) {
      let product = selected[0];
      setProductUuid(product.objectUuid);
    }
  }

  function handleFromLocationChange(selected) {
    if (selected[0] != undefined) {
      let location = selected[0];
      setFromLocationUuid(location.objectUuid);
    }
  }

  function handleToLocationChange(selected) {
    if (selected[0] != undefined) {
      let location = selected[0];
      setToLocationUuid(location.objectUuid);
    }
  }

  return (
    <div className="NewInventory">
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="objectUuid">
          <Form.Label>Product</Form.Label>
          <Typeahead
            labelKey="productName"
            id={1}
            multiple={false}
            options={products}
            onChange={(e) => handleProductChange(e)}
            placeholder="Choose product..."
            value={productUuid}
          />
        </Form.Group>
        <Form.Group controlId="objectUuid">
          <Form.Label>From location</Form.Label>
          <Typeahead
            labelKey="locationName"
            id={2}
            multiple={false}
            onChange={(e) => handleFromLocationChange(e)}
            options={locations}
            placeholder="Choose location to move from..."
            value={fromLocationUuid}
          />
        </Form.Group>
        <Form.Group controlId="objectUuid">
          <Form.Label>To location</Form.Label>
          <Typeahead
            labelKey="locationName"
            multiple={false}
            id={3}
            onChange={(e) => handleToLocationChange(e)}
            options={locations}
            placeholder="Choose location to move to..."
            value={toLocationUuid}
          />
        </Form.Group>
        <Form.Group controlId="changeValue">
          <Form.Label>Change</Form.Label>
          <Form.Control
            onChange={(e) => setChangeValue(e.target.value)}
            value={changeValue}
            as="input"
          />
        </Form.Group>
        <LoaderButton
          block
          type="submit"
          size="lg"
          variant="primary"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Create
        </LoaderButton>
      </Form>
    </div>
  );
}
