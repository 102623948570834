import React, { useRef, useState } from "react";
import { API } from "aws-amplify";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import LoaderButton from "../../components/LoaderButton";
import { onError } from "../../libs/errorLib";
import "./NewProduct.css";

export default function NewProduct() {

    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const [productName, setProductName] = useState("");
    const [productId, setProductId] = useState("");

    function validateForm() {
        return productName.length > 0;
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
            await createProduct({ productName, productId });
            history.push("/products");
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    }

    function createProduct(product) {
        return API.post("inventory", "/products", {
            body: product
        });
    }

    return (
        <div className="NewProduct">
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="productName">
                    <Form.Label>Product name</Form.Label>
                    <Form.Control
                        value={productName}
                        as="input"
                        onChange={(e) => setProductName(e.target.value)}
                    />
                </Form.Group>
                <Form.Group controlId="productId">
                    <Form.Label>Product id (unique)</Form.Label>
                    <Form.Control
                        value={productId}
                        as="input"
                        onChange={(e) => setProductId(e.target.value)}
                    />
                </Form.Group>
                <LoaderButton
                    block
                    type="submit"
                    size="lg"
                    variant="primary"
                    isLoading={isLoading}
                    disabled={!validateForm()}
                >Create
                </LoaderButton>
            </Form>
        </div>
    );
}
