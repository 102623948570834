import React, { useRef, useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { API, Storage } from "aws-amplify";
import { useParams, useHistory } from "react-router-dom";
import LoaderButton from "../../components/LoaderButton";
import { onError } from "../../libs/errorLib";
import { s3Upload } from "../../libs/awsLib";
import config from "../../config";
import "./EditLocation.css";

export default function Location() {
  const file = useRef(null);
  const { id } = useParams();
  const history = useHistory();
  const [location, setLocation] = useState(null);
  const [locationName, setLocationName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    function loadLocation() {
      return API.get("inventory", `/locations/${id}`);
    }

    async function onLoad() {

      try {

        const location = await loadLocation();
        const { locationName }  = location;

        setLocationName(locationName);
        setLocation(location);

      } catch (e) {

        onError(e);

      }

    }

    onLoad();

  }, [id]);

  function validateForm() {
    return locationName.length > 0;
  }


  function saveLocation(location) {
    return API.put("inventory", `/locations/${id}`, {
      body: location
    });
  }

  async function handleSubmit(event) {

    event.preventDefault();

    setIsLoading(true);

    try {

      await saveLocation({

        locationName

      });

      history.push("/locations");

    } catch (e) {

      onError(e);
      setIsLoading(false);

    }

  }

  function deleteLocation() {
    return API.del("inventory", `/locations/${id}`);
  }

  async function handleDelete(event) {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this location?"
    );

    if (!confirmed) {
      return;
    }

    setIsDeleting(true);

    try {

      await deleteLocation();

      history.push("/locations");

    } catch (e) {

      onError(e);
      setIsDeleting(false);

    }

  }

  return (
    <div className="Location">
      {location && (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="locationName">
              Name
              <Form.Control
                as="input"
                onChange={(e) => setLocationName(e.target.value)}
                value={locationName}
              />
            </Form.Group>
          <LoaderButton
            block
            size="lg"
            type="submit"
            isLoading={isLoading}
            disabled={!validateForm()}
          >
            Save
          </LoaderButton>
          {location.locationType == 'STORE' && <LoaderButton
            block
            size="lg"
            variant="danger"
            onClick={handleDelete}
            isLoading={isDeleting}
          >
            Delete
          </LoaderButton>}
        </Form>
      )}
    </div>
  );
}
