import React, { Component } from "react";
import { API, Storage } from "aws-amplify";
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import config from "../../config";
import "./EditInventory.css";
import { s3Upload } from "../../libs/awsLib";

export default class EditInventory extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
        isLoading: null,
        isDeleting: null,
        inventoryName: "",
        inventoryDescription: "",
      };
  }

  async componentDidMount() {
    try {
      const inventory = await this.getInventory();
      const { inventoryName, inventoryDescription } = inventory;

      this.setState({
        inventoryName,
        inventoryDescription,
      });
    } catch (e) {
      alert(e);
    }
  }

  getInventory() {
    return API.get("inventory", `/inventory/${this.props.match.params.id}`);
  }

  validateForm() {
    return this.state.inventoryName.length > 0 &&
          this.state.inventoryDescription.length > 0;
  }

  formatFileinventoryName(str) {
    return str.replace(/^\w+-/, "");
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  saveNote(inventory) {
    return API.put("inventory", `/inventory/${this.props.match.params.id}`, {
      body: inventory
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {

      await this.saveNote({
        inventoryName: this.state.inventoryName,
        inventoryDescription: this.state.inventoryDescription
      });
      this.props.history.push("/inventory");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }

  deleteInventory() {
    return API.del("inventory", `/inventory/${this.props.match.params.id}`);
  }

  handleDelete = async event => {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this inventory?"
    );

    if (!confirmed) {
      return;
    }

    this.setState({ isDeleting: true });

    try {
      await this.deleteInventory();
      this.props.history.push("/inventory");
    } catch (e) {
      alert(e);
      this.setState({ isDeleting: false });
    }
  }

  render() {
    return (
      <div className="Inventory">
        {this.state.inventoryName &&
          <form onSubmit={this.handleSubmit}>
            <FormGroup controlId="inventoryName">
              <FormControl
                onChange={this.handleChange}
                value={this.state.inventoryName}
                componentClass="input"
              />
            </FormGroup>
            <FormGroup controlId="inventoryDescription">
              <FormControl
                onChange={this.handleChange}
                value={this.state.inventoryDescription}
                componentClass="input"
              />
            </FormGroup>
            <LoaderButton
              block
              bsStyle="primary"
              bsSize="large"
              disabled={!this.validateForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Save"
              loadingText="Saving…"
            />
            <LoaderButton
              block
              bsStyle="danger"
              bsSize="large"
              isLoading={this.state.isDeleting}
              onClick={this.handleDelete}
              text="Delete"
              loadingText="Deleting…"
            />
          </form>}
      </div>
    );
  }
}