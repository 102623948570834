import React, { useRef, useState } from "react";
import { API } from "aws-amplify";
import { Form } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { onError } from "../../libs/errorLib";
import { useHistory } from "react-router-dom";
import "./NewArticle.css";
import uuid from 'react-native-uuid';

export default function NewArticle() {

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [sku, setSku] = useState("");


  function validateForm() {
    return name.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await createArticle({
        uuid: uuid.v4(),
        name,
        description,
        sku,
        productUuid: 'undefined'
      });
      history.push("/articles");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function createArticle(container) {
    return API.post("inventory", `/articles`, {
      body: container
    });
  }


  return (
    <div className="NewArticle">
      <form onSubmit={handleSubmit}>
        <Form.Group controlId="productName">
          <Form.Label>Article Name</Form.Label>
          <Form.Control
            value={name}
            as="input"
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="containerDescription">
          <Form.Label>Description</Form.Label>
          <Form.Control
            value={description}
            as="input"
            onChange={(e) => setDescription(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="containerDescription">
          <Form.Label>Stock Code Unit (must be unique)</Form.Label>
          <Form.Control
            value={sku}
            as="input"
            onChange={(e) => setSku(e.target.value)}
          />
        </Form.Group>
        <LoaderButton
          block
          type="submit"
          variant="primary"
          disabled={!validateForm()}
          isLoading={isLoading}
        >Create
        </LoaderButton>
      </form>
    </div>
  );

}