import React, { useState, useEffect } from "react";
import { ListGroup, Button } from "react-bootstrap";
import "./Articles.css";
import { BsPencilSquare } from "react-icons/bs";
import { API } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../../libs/contextLib";
import { onError } from "../../libs/errorLib";
import { LinkContainer } from "react-router-bootstrap";

export default function Articles() {
  const history = useHistory();
  const [articles, setArticles] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      try {
        const articles = await loadArticles();
        setArticles(articles);
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  function loadArticles() {
    return API.get("inventory", `/articles`);
  }

  function renderArticlesList(articles) {
    return (
      <>
        <LinkContainer to="/articles/new">
          <ListGroup.Item action className="py-3 text-nowrap text-truncate">
            <BsPencilSquare size={17} />
            <span className="ml-2 font-weight-bold">Create a new article</span>
          </ListGroup.Item >
        </LinkContainer>
        {articles.map(({ uuid, name, created }) => (
          <LinkContainer key={uuid} to={`/articles/${uuid}`}>
            <ListGroup.Item action>
              <span className="font-weight-bold">
                {name}
              </span>
              <br />
              <span className="text-muted">
                Created: {new Date(created).toLocaleString()}
              </span>
            </ListGroup.Item>
          </LinkContainer>
        ))
        }
      </>
    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>inven2ry.com</h1>
        <p>Your webshop warehouse partner</p>
      </div>
    );
  }


  function renderArticles() {
    return (
      <div className="articles">
        <h2 className="pb-3 mt-4 mb-3 border-bottom">Warehouse Articles</h2>
        <ListGroup>
          {!isLoading && renderArticlesList(articles)}
        </ListGroup>
      </div>
    );
  }

  return (
    <div className="Articles">
      {isAuthenticated ? renderArticles() : renderLander()}
    </div>
  );

}